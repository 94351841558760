






















import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import FarmUnitDisplay from '@/views/swine/farmUnit/FarmUnitDisplay.vue';
import { FarmUnit } from '@/models/swine/farmUnit/farmUnit.model';

@Component({
  components: {
    FarmUnitDisplay,
  },
})
export default class FarmUnitContainer extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  farmCode!: string;

  @Prop({
    type: Array,
    default: [],
  })
  farmUnits!: FarmUnit[];

  currentFarmUnits: FarmUnit[] = [];

  addButtonDisabled = true;

  @Watch('farmCode')
  @Emit('updateFarmUnits')
  updateAllFarmUnits() {
    if (this.farmCode) {
      this.addButtonDisabled = false;
      this.currentFarmUnits = this.farmUnits.map((unit, index) => {
        const farmUnitCode = `${this.farmCode}-U${index + 1}`;
        return {
          ...unit,
          farmUnitCode,
        };
      });
    } else {
      this.addButtonDisabled = true;
    }
    return this.currentFarmUnits;
  }

  @Watch('farmUnits', { deep: true })
  updateFarmUnits() {
    this.currentFarmUnits = [...this.farmUnits];
  }

  mounted() {
    this.updateFarmUnits();
  }

  addFarmUnit() {
    this.createDefaultFarmUnit(this.farmUnits.length);
  }

  @Emit('updateFarmUnits')
  removeFarmUnit() {
    this.currentFarmUnits.pop();
    return this.currentFarmUnits;
  }

  @Emit('updateFarmUnits')
  createDefaultFarmUnit(prevUnitCode: number) {
    const farmUnitCode = this.farmCode
      ? `${this.farmCode}-U${prevUnitCode + 1}`
      : '';
    const newUnit: FarmUnit = {
      farmUnitCode,
      auditReportCount: 0,
    };
    this.currentFarmUnits.push(newUnit);
    return this.currentFarmUnits;
  }
}
