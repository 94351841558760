


































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import FarmFormTemplate from '@/views/swine/farm/FarmFormTemplate.vue';
import { Farm } from '@/models/swine/farm/farm.model';
import {
  DropdownOptionRequest,
  DropdownOptionResponse,
} from '@/models/swine/client/client.model';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { ClientStores } from '@/store/swine/client/index';

interface FarmUpdateObject {
  farm: Farm;
  index: number;
}

@Component({
  components: {
    FarmFormTemplate,
  },
})
export default class ClientFarmContainer extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  clientCode!: string;

  @Prop({
    type: Array,
    default: [],
  })
  farms!: Farm[];

  @Prop({
    type: Array,
    default: [],
  })
  countries!: DropdownOption[];

  @Prop({
    type: Object,
    default: null,
  })
  regions!: DropdownOptionResponse;

  @Prop({
    type: Object,
    default: null,
  })
  states!: DropdownOptionResponse;

  @Prop({
    type: Object,
    default: null,
  })
  cities!: DropdownOptionResponse;

  @Prop({
    type: Boolean,
    default: false,
  })
  isCountryDropdownLock!: boolean;

  @Prop({
    type: Number,
    default: null,
  })
  fixedCountryId!: number;

  currentFarmList: Farm[] = [];
  currentId = new Date().getTime();

  store = ClientStores.detail;

  @Watch('farms', { deep: true })
  updateFarms() {
    this.currentFarmList = [...this.farms];
  }

  mounted() {
    this.updateFarms();

    // We dont want this to run when editing farm since the array should be populated already
    if (
      this.currentFarmList.length < 1 &&
      window.location.href.indexOf('edit') === -1
    ) {
      this.createDefaultFarm();
    }
  }

  @Emit('updateFarms')
  updateFarm(params: FarmUpdateObject) {
    const { farm, index } = params;

    // We create a new temp list to prevent updating/emitting issues
    const tempNewFarmList = [...this.currentFarmList];
    tempNewFarmList[index] = farm;
    return tempNewFarmList;
  }

  addFarm() {
    this.createDefaultFarm();
  }

  @Emit('updateFarms')
  removeFarm() {
    this.currentFarmList.pop();
    return this.currentFarmList;
  }

  @Emit('updateFarms')
  createDefaultFarm() {
    const farmCode = this.clientCode
      ? `${this.clientCode}-F${this.currentFarmList.length + 1}`
      : '';

    const newFarm: Farm = {
      id: this.currentId,
      name: '',
      addressLine1: '',
      countryId: '',
      regionId: '',
      cityId: '',
      stateId: '',
      postcode: '',
      phoneNumber: '',
      farmCode,
      farmUnits: [
        {
          farmUnitCode: '',
          auditReportCount: 0,
        },
      ],
    };

    this.currentFarmList.push(newFarm);
    this.currentId++;

    return this.currentFarmList;
  }

  @Emit('requestRegion')
  requestRegion(params: DropdownOptionRequest) {
    return params;
  }

  @Emit('requestState')
  requestState(params: DropdownOptionRequest) {
    return params;
  }

  @Emit('requestCity')
  requestCity(params: DropdownOptionRequest) {
    return params;
  }

  get addButtonDisabled() {
    const emptyFarmName = this.currentFarmList.some((farm) => farm.name === '');
    if (this.clientCode && !emptyFarmName) {
      return false;
    }
    return true;
  }

  get isEditPage() {
    if (this.store.isEditPage === true) {
      return false;
    }
    return true;
  }
}
