





























































































import Dropdown from '@/components/swine/Dropdown.vue';
import {
  DEFAULT_CLIENT_COUNTRIES_CREATE,
  DEFAULT_SELECT_OPTION,
} from '@/utils/constants';
import { errorMessages, validatePhoneNumber } from '@/utils/validation';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import FarmUnitContainer from '@/views/swine/farm/FarmUnitContainer.vue';
import { FarmUnit } from '@/models/swine/farmUnit/farmUnit.model';
import { Farm } from '@/models/swine/farm/farm.model';
import { DropdownOptionResponse } from '@/models/swine/client/client.model';
import { generateUniqueId } from '@/utils/lodash';

@Component({
  components: {
    Dropdown,
    FarmUnitContainer,
  },
})
export default class FarmFormTemplate extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  clientCode!: string;

  @Prop({
    type: Number,
    default: 1,
  })
  farmIndex!: number;

  @Prop({
    type: Object,
    default: null,
  })
  farm!: Partial<Farm>;

  @Prop({
    type: Array,
    default: [],
  })
  countries!: DropdownOption[];

  @Prop({
    type: Object,
    default: null,
  })
  regions!: DropdownOptionResponse;

  @Prop({
    type: Object,
    default: null,
  })
  states!: DropdownOptionResponse;

  @Prop({
    type: Object,
    default: null,
  })
  cities!: DropdownOptionResponse;

  @Prop({
    type: Boolean,
    default: false,
  })
  isCountryDropdownLock!: boolean;

  @Prop({
    type: Number,
    default: null,
  })
  fixedCountryId!: number;

  // Local dropdown options
  countryOptions: DropdownOption[] = DEFAULT_CLIENT_COUNTRIES_CREATE;
  regionOptions: DropdownOption[] = DEFAULT_SELECT_OPTION;
  stateOptions: DropdownOption[] = DEFAULT_SELECT_OPTION;
  cityOptions: DropdownOption[] = DEFAULT_SELECT_OPTION;

  // These are used to track who called this request to prevent the dropdown values from updating everywhere
  regionDropdownRequestCode = 0;
  stateDropdownRequestCode = 0;
  citiesDropdownRequestCode = 0;

  errorMessages = errorMessages;

  nameError = false;
  phoneError = false;

  id = 1;
  name = '';
  streetAddress1 = '';
  streetAddress2 = '';
  selectedCountry = '';
  selectedRegion = '';
  selectedState = '';
  selectedCity = '';
  postcode = '';
  phoneNumber = '';
  farmCode = '';

  farmUnits: FarmUnit[] = [];

  hasRetrieved = false;
  isRegionSelectionDisabled = false;

  @Watch('clientCode')
  @Watch('farmIndex')
  updateFarmCode() {
    if (this.clientCode && this.farmIndex) {
      this.farmCode = `${this.clientCode}-F${this.farmIndex}`;
    }
    this.returnFarmObject();
  }

  @Watch('farm', { deep: true })
  updateFarmDetails() {
    if (this.farm.id) {
      this.id = this.farm.id;
    }
    this.name = this.farm.name ?? '';
    if (this.farm.addressLine1) {
      const streetAddress = this.farm.addressLine1.split('\n');
      this.streetAddress1 = streetAddress[0];
      this.streetAddress2 = streetAddress[1];
    }
    if (this.fixedCountryId && this.isCountryDropdownLock) {
      this.selectedCountry = this.fixedCountryId.toString();
    } else {
      this.selectedCountry = this.farm.countryId
        ? String(this.farm.countryId)
        : '';
    }
    this.selectedRegion = this.farm.regionId ? String(this.farm.regionId) : '';
    this.selectedState = this.farm.stateId ? String(this.farm.stateId) : '';
    this.selectedCity = this.farm.cityId ? String(this.farm.cityId) : '';
    this.postcode = this.farm.postcode ?? '';
    this.phoneNumber = this.farm.phoneNumber ?? '';
    this.farmCode = this.farm.farmCode ?? '';
    this.farmUnits = this.farm.farmUnits ?? [];
  }

  @Watch('countries')
  updateCountryOptions() {
    this.countryOptions = this.countries;
    if (this.fixedCountryId && this.isCountryDropdownLock) {
      this.selectedCountry = this.fixedCountryId.toString();
      const index = this.countryOptions.findIndex((data) => {
        return data.value === this.selectedCountry;
      });
      if (index > -1) {
        this.updateCountry(this.countryOptions[index]);
      }
    }
  }

  @Watch('regions')
  updateRegionOptions() {
    if (
      this.regionDropdownRequestCode === this.regions.code ||
      this.regions.code === 0
    ) {
      this.regionOptions = this.regions.list;

      // This is to fix updating issues
      if (this.regionOptions && this.regionOptions.length > 1) {
        let temp = this.selectedRegion;
        this.isRegionSelectionDisabled = false;
        const notAvailableIndex = this.regions.list.findIndex((data) => {
          return data.text === 'N/A';
        });
        if (
          notAvailableIndex > -1 &&
          this.regions.list[notAvailableIndex] &&
          this.regions.list[notAvailableIndex].value !== this.selectedRegion
        ) {
          temp = this.regions.list[notAvailableIndex].value; // Get the N/A value
          // That means no region
          this.isRegionSelectionDisabled = true;
          this.updateRegion({
            text: temp,
            value: temp,
            uniqueKey: generateUniqueId(`${temp}_`),
          });
        }
        this.selectedRegion = '';
        setTimeout(() => {
          this.selectedRegion = temp;
        }, 50);
      }
    }
  }

  @Watch('states')
  updateStateOptions() {
    if (this.stateDropdownRequestCode === this.states.code) {
      this.stateOptions = this.states.list;

      // This is to fix updating issues
      if (this.stateOptions && this.stateOptions.length > 1) {
        const temp = this.selectedState;
        this.selectedState = '';
        setTimeout(() => {
          this.selectedState = temp;
        }, 50);
      }
    }
  }

  @Watch('cities')
  updateCityOptions() {
    if (this.citiesDropdownRequestCode === this.cities.code) {
      this.cityOptions = this.cities.list;

      // This is to fix updating issues
      if (this.cityOptions && this.cityOptions.length > 1) {
        const temp = this.selectedCity;
        this.selectedCity = '';
        setTimeout(() => {
          this.selectedCity = temp;
        }, 50);
      }
    }
  }

  mounted() {
    this.updateFarmDetails();
  }

  updateName(params: string) {
    if (params === '') {
      this.nameError = true;
    } else {
      this.nameError = false;
    }

    this.name = params;
    this.returnFarmObject();
  }

  updateStreetAddress1(params: string) {
    this.streetAddress1 = params;
    this.returnFarmObject();
  }

  updateStreetAddress2(params: string) {
    this.streetAddress2 = params;
    this.returnFarmObject();
  }

  @Emit('requestRegion')
  updateCountry(params: DropdownOption) {
    if (params && params.value) {
      this.selectedCountry = params.value;
    }
    this.returnFarmObject();

    this.regionDropdownRequestCode = new Date().getTime();
    return {
      code: this.regionDropdownRequestCode,
      value: params.value,
    };
  }

  @Emit('requestState')
  updateRegion(params: DropdownOption) {
    if (params && params.value) {
      this.selectedRegion = params.value;
    }
    this.returnFarmObject();

    this.stateDropdownRequestCode = new Date().getTime();
    return {
      code: this.stateDropdownRequestCode,
      value: params.value,
    };
  }

  @Emit('requestCity')
  updateState(params: DropdownOption) {
    this.selectedState = params.value;
    this.returnFarmObject();

    this.citiesDropdownRequestCode = new Date().getTime();
    return {
      code: this.citiesDropdownRequestCode,
      value: params.value,
    };
  }

  updateCity(params: DropdownOption) {
    if (params && params.value) {
      this.selectedCity = params.value;
    }
    this.returnFarmObject();
  }

  updatePostcode(params: string) {
    this.postcode = params;
    this.returnFarmObject();
  }

  updatePhoneNumber(params: string) {
    this.phoneError = !validatePhoneNumber(params);

    this.returnFarmObject();
  }

  updateFarmUnits(params: FarmUnit[]) {
    this.farmUnits = params;
    this.returnFarmObject();
  }

  @Emit('updateFarm')
  returnFarmObject() {
    // Return object to parent
    if (this.clientCode && this.farmIndex) {
      this.farmCode = `${this.clientCode}-F${this.farmIndex}`;
    }

    const newFarmObject: Farm = {
      name: this.name,
      addressLine1: `${this.streetAddress1}\n${this.streetAddress2}`,
      countryId: this.selectedCountry,
      regionId: this.selectedRegion,
      stateId: this.selectedState,
      cityId: this.selectedCity,
      postcode: this.postcode,
      phoneNumber: this.phoneError ? '' : this.phoneNumber,
      farmCode: this.farmCode,
      farmUnits: this.farmUnits,
    };

    return {
      farm: newFarmObject,
      index: this.farmIndex - 1,
    };
  }

  @Watch('selectedCountry')
  @Watch('selectedRegion')
  @Watch('selectedState')
  @Watch('selectedCity')
  getDropdownOptions() {
    if (this.editing) {
      this.retrieveSelectedList();
    }
  }

  retrieveSelectedList() {
    if (!this.hasRetrieved) {
      if (this.selectedCountry !== '') {
        this.updateCountry({
          text: this.selectedCountry,
          value: this.selectedCountry,
          uniqueKey: generateUniqueId(`${this.selectedCountry}_`),
        });
      }
      if (this.selectedRegion !== '') {
        this.updateRegion({
          text: this.selectedRegion,
          value: this.selectedRegion,
          uniqueKey: generateUniqueId(`${this.selectedRegion}_`),
        });
      }
      if (this.selectedState !== '') {
        this.updateState({
          text: this.selectedState,
          value: this.selectedState,
          uniqueKey: generateUniqueId(`${this.selectedState}_`),
        });
      }
      if (this.selectedCity !== '') {
        this.updateCity({
          text: this.selectedCity,
          value: this.selectedCity,
          uniqueKey: generateUniqueId(`${this.selectedCity}_`),
        });
      }
      this.hasRetrieved = true;
    }
  }

  get editing() {
    if (window.location.href.indexOf('edit') > -1) {
      return true;
    }
    return false;
  }
}
