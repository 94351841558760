


























































































































import Dropdown from '@/components/swine/Dropdown.vue';
import { errorMessages, validatePhoneNumber } from '@/utils/validation';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import ClientFarmContainer from '@/views/swine/client/ClientFarmContainer.vue';
import { Farm } from '@/models/swine/farm/farm.model';
import {
  Client,
  DropdownOptionRequest,
  DropdownOptionResponse,
} from '@/models/swine/client/client.model';
import {
  DEFAULT_CLIENT_COUNTRIES_CREATE,
  DEFAULT_SELECT_OPTION,
} from '@/utils/constants';
import { generateUniqueId } from '@/utils/lodash';

@Component({
  components: {
    Dropdown,
    ClientFarmContainer,
  },
})
export default class ClientFormTemplate extends Vue {
  errorMessages = errorMessages;

  @Prop({
    type: Object,
    default: null,
  })
  clientDetails!: Client;

  @Prop({
    type: Array,
    default: [],
  })
  countries!: DropdownOption[];

  @Prop({
    type: Object,
    default: null,
  })
  regions!: DropdownOptionResponse;

  @Prop({
    type: Object,
    default: null,
  })
  states!: DropdownOptionResponse;

  @Prop({
    type: Object,
    default: null,
  })
  cities!: DropdownOptionResponse;

  @Prop({
    type: Boolean,
    default: false,
  })
  isCountryDropdownLock!: boolean;

  @Prop({
    type: Number,
    default: null,
  })
  fixedCountryId!: number;

  // Local dropdown options
  countryOptions: DropdownOption[] = DEFAULT_CLIENT_COUNTRIES_CREATE;
  regionOptions: DropdownOption[] = DEFAULT_SELECT_OPTION;
  stateOptions: DropdownOption[] = DEFAULT_SELECT_OPTION;
  cityOptions: DropdownOption[] = DEFAULT_SELECT_OPTION;

  // These are used to track who called this request to prevent the dropdown values from updating everywhere
  regionDropdownRequestCode = 0;
  stateDropdownRequestCode = 0;
  citiesDropdownRequestCode = 0;

  nameError = false;
  phoneError = false;
  countryError = false;
  regionError = false;
  stateError = false;
  cityError = false;

  name = '';
  streetAddress1 = '';
  streetAddress2 = '';
  selectedCountry = '';
  selectedRegion = '';
  selectedState = '';
  selectedCity = '';

  postcode = '';
  phoneNumber = '';
  clientCode = '';

  farms: Farm[] = [];

  hasRetrieved = false;
  isRegionSelectionDisabled = false;

  mounted() {
    this.updateDetails();
  }

  @Watch('clientDetails', { deep: true })
  updateDetails() {
    this.name = this.clientDetails.name ?? '';
    if (this.clientDetails.addressLine1) {
      const streetAddress = this.clientDetails.addressLine1.split('\n');
      this.streetAddress1 = streetAddress[0];
      this.streetAddress2 = streetAddress[1];
    }
    this.name = this.clientDetails.name ?? '';
    if (this.fixedCountryId && this.isCountryDropdownLock) {
      this.selectedCountry = this.fixedCountryId.toString();
    } else {
      this.selectedCountry = this.clientDetails.countryId
        ? String(this.clientDetails.countryId)
        : '';
    }
    this.selectedRegion = this.clientDetails.regionId
      ? String(this.clientDetails.regionId)
      : '';
    this.selectedState = this.clientDetails.stateId
      ? String(this.clientDetails.stateId)
      : '';
    this.selectedCity = this.clientDetails.cityId
      ? String(this.clientDetails.cityId)
      : '';
    this.postcode = this.clientDetails.postcode ?? '';
    this.phoneNumber = this.clientDetails.phoneNumber ?? '';
    this.clientCode = this.clientDetails.clientCode ?? '';
    this.farms = this.clientDetails.farms ?? [];
  }

  @Watch('countries')
  updateCountryOptions() {
    this.countryOptions = this.countries;
    // Only apply towards Create Client only
    if (this.fixedCountryId && this.isCountryDropdownLock && !this.editing) {
      this.selectedCountry = this.fixedCountryId.toString();
      const index = this.countryOptions.findIndex((data) => {
        return data.value === this.selectedCountry;
      });
      if (index > -1) {
        this.updateCountry(this.countryOptions[index]);
      }
    }
  }

  @Watch('regions')
  updateRegionOptions() {
    if (
      this.regionDropdownRequestCode === this.regions.code ||
      this.regions.code === 0
    ) {
      this.regionOptions = this.regions.list;
      // This is to fix updating issues
      if (this.regionOptions && this.regionOptions.length > 1) {
        let temp: string | number = this.selectedRegion;
        this.isRegionSelectionDisabled = false;
        const notAvailableIndex = this.regions.list.findIndex((data) => {
          return data.text === 'N/A';
        });
        if (
          notAvailableIndex > -1 &&
          this.regions.list[notAvailableIndex] &&
          this.regions.list[notAvailableIndex].value !== this.selectedRegion
        ) {
          temp = this.regions.list[notAvailableIndex].value; // Get the N/A value
          this.isRegionSelectionDisabled = true;
          // That means no region
          this.updateRegion({
            text: String(temp),
            value: temp,
            uniqueKey: generateUniqueId(`${String(temp)}_`),
          });
        }
        this.selectedRegion = '';
        setTimeout(() => {
          this.selectedRegion = String(temp);
        }, 50);
      }
    }
  }

  @Watch('states')
  updateStateOptions() {
    if (
      this.stateDropdownRequestCode === this.states.code ||
      this.states.code === 0
    ) {
      this.stateOptions = this.states.list;
      // This is to fix updating issues
      if (this.stateOptions && this.stateOptions.length > 1) {
        const temp = this.selectedState;
        this.selectedState = '';
        setTimeout(() => {
          this.selectedState = temp;
        }, 50);
      }
    }
  }

  @Watch('cities')
  updateCityOptions() {
    if (
      this.citiesDropdownRequestCode === this.cities.code ||
      this.cities.code === 0
    ) {
      this.cityOptions = this.cities.list;

      // This is to fix updating issues
      if (this.cityOptions && this.cityOptions.length > 1) {
        const temp = this.selectedCity;
        this.selectedCity = '';
        setTimeout(() => {
          this.selectedCity = temp;
        }, 50);
      }
    }
  }

  @Emit('nameChange')
  updateName(params: string) {
    if (params === '') {
      this.nameError = true;
    } else {
      this.nameError = false;
    }
    return params;
  }

  @Emit('streetAddressChange')
  updateStreetAddress1(params: string) {
    return `${params}\n${this.streetAddress2}`;
  }

  @Emit('streetAddressChange')
  updateStreetAddress2(params: string) {
    return `${this.streetAddress1}\n${params}`;
  }

  @Emit('countryChange')
  updateCountry(params: DropdownOption) {
    this.regionDropdownRequestCode = new Date().getTime();
    return {
      code: this.regionDropdownRequestCode,
      value: params.value,
    };
  }

  @Emit('regionChange')
  updateRegion(params: DropdownOption) {
    this.stateDropdownRequestCode = new Date().getTime();
    return {
      code: this.stateDropdownRequestCode,
      value: params.value,
    };
  }

  @Emit('stateChange')
  updateState(params: DropdownOption) {
    this.citiesDropdownRequestCode = new Date().getTime();
    return {
      code: this.citiesDropdownRequestCode,
      value: params.value,
    };
  }

  @Emit('cityChange')
  updateCity(params: DropdownOption) {
    return params.value;
  }

  @Emit('postcodeChange')
  updatePostcode(params: string) {
    return params;
  }

  @Emit('phoneChange')
  updatePhoneNumber(params: string) {
    this.phoneError = !validatePhoneNumber(params);

    if (this.phoneError) {
      return '';
    }
    return params;
  }

  @Emit('farmChange')
  updateFarms(params: Farm[]) {
    return params;
  }

  @Emit('requestRegion')
  requestRegion(params: DropdownOptionRequest) {
    return params;
  }

  @Emit('requestState')
  requestState(params: DropdownOptionRequest) {
    return params;
  }

  @Emit('requestCity')
  requestCity(params: DropdownOptionRequest) {
    return params;
  }

  @Watch('selectedCountry')
  @Watch('selectedRegion')
  @Watch('selectedState')
  @Watch('selectedCity')
  checkErrors() {
    if (this.selectedCountry === '') {
      this.countryError = true;
    } else {
      this.countryError = false;
    }
    if (
      (this.selectedRegion === '' && this.selectedCountry === '1') ||
      (this.selectedRegion === '' && this.selectedCountry === '2')
    ) {
      this.regionError = true;
    } else {
      this.regionError = false;
    }
    if (this.selectedState === '') {
      this.stateError = true;
    } else {
      this.stateError = false;
    }

    if (this.editing) {
      this.retrieveSelectedList();
    }
  }

  retrieveSelectedList() {
    if (!this.hasRetrieved) {
      if (this.selectedCountry !== '') {
        this.updateCountry({
          text: this.selectedCountry,
          value: this.selectedCountry,
          uniqueKey: generateUniqueId(`${this.selectedCountry}_`),
        });
      }
      if (this.selectedRegion !== '') {
        this.updateRegion({
          text: this.selectedRegion,
          value: this.selectedRegion,
          uniqueKey: generateUniqueId(`${this.selectedRegion}_`),
        });
      }
      if (this.selectedState !== '') {
        this.updateState({
          text: this.selectedState,
          value: this.selectedState,
          uniqueKey: generateUniqueId(`${this.selectedState}_`),
        });
      }
      if (this.selectedCity !== '') {
        this.updateCity({
          text: this.selectedCity,
          value: this.selectedCity,
          uniqueKey: generateUniqueId(`${this.selectedCity}_`),
        });
      }
      this.hasRetrieved = true;
    }
  }

  get editing() {
    if (window.location.href.indexOf('edit') > -1) {
      return true;
    }
    return false;
  }
}
