




















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ClientStores } from '@/store/swine/client/index';

@Component({})
export default class FarmUnitDisplay extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  unitCode!: string;

  store = ClientStores.detail;

  
  get isEditPage() {
    if (this.store.isEditPage === true) {
      return false;
    }
    return true;
  }

  get farmId() {
    if (this.unitCode) {
      const codes = this.unitCode.split('-');
      // Farm code would be the second last
      const farmCode = codes[codes.length - 2];
      return farmCode.substring(1);
    }
    return 1;
  }

  get unitNumber() {
    if (this.unitCode) {
      const codes = this.unitCode.split('-');
      // Unit code would be the last
      const unitCode = codes[codes.length - 1];
      return unitCode.substring(1);
    }
    return 1;
  }

  @Emit('removeFarmUnit')
  removeFarmUnit() {
    return true;
  }
}
